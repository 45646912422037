<template>
  <div
    class="Infos"
    data-test-id=""
  >
    <h3>{{ $t('organization.contact.infos.title') }}</h3>
    <Info
      v-if="editMode || hasLocation"
      type="address"
    >
      <template #content>
        <p
          class="Info--Text"
          data-test-id="CoordinatesAddress"
        >
          <template
            v-if="
              organization.address &&
              organization.address !== organization.city
            "
          >
            {{ organization.address }}<br />
          </template>
          {{ cityLabel }}
        </p>
      </template>
    </Info>
    <Info
      v-if="editMode || contact.email"
      type="email"
      is-deletable
    >
      <template #content>
        <ha-button
          v-if="!isEmailShown && !editMode"
          variant="link"
          size="large"
          data-ux="Explore_OrganizationPublicPage_Contact_ShowEmail"
          data-test-id="Explore_OrganizationPublicPage_Contact_ShowEmail"
          @click="showEmail()"
        >
          {{ $t('organization.contact.infos.email.showEmail') }}
        </ha-button>
        <p
          v-else
          class="Info--Text"
          data-test-id="CoordinatesEmail"
        >
          {{ contact.email }}
        </p>
      </template>
    </Info>
    <Info
      v-if="editMode || contact.phoneNumber"
      type="phone"
      is-deletable
    >
      <template #content>
        <ha-button
          v-if="!isPhoneNumberShown && !editMode"
          variant="link"
          size="large"
          data-ux="Explore_OrganizationPublicPage_Contact_ShowPhone"
          @click="showPhoneNumber()"
        >
          {{ $t('organization.contact.infos.phone.showPhoneNumber') }}
        </ha-button>
        <p
          v-else
          class="Info--Text"
        >
          {{ contact.phoneNumber }}
        </p>
      </template>
    </Info>
    <HaSwitch
      v-if="editMode"
      :model-value="displayCoordinates"
      name="displayCoordinates"
      @update:model-value="
        $emit('change-display-coordinates', $event)
      "
    >
      {{
        $t(
          'organization.contact.infos.coordinates.displayCoordinates'
        )
      }}
    </HaSwitch>
    <ha-button
      v-if="!editMode && contact.email"
      fluid
      data-ux="Explore_OrganizationPublicPage_Contact_ContactUs"
      @click="openMail"
    >
      {{ $t('organization.contact.infos.contactUs') }}
    </ha-button>
  </div>
</template>

<script setup lang="ts">
import { get } from 'lodash-es'
import { ref, computed } from '#imports'
import { HaButton, HaSwitch } from '#components'
import Info from '@/components/Organization/Partials/Info.vue'
import useEditMode from '@/composables/useEditMode'
import { useOrganizationStore } from '@/store/organization.store'

const { editMode } = useEditMode()
const organizationStore = useOrganizationStore()

const isPhoneNumberShown = ref(false)
const isEmailShown = ref(false)
const displayCoordinates = ref(
  organizationStore.organization?.displayCoordinates
)

const organization = computed(() => {
  return organizationStore.organization
})
const contact = computed(() => {
  return get(organization.value, 'contact', {
    email: null,
    phoneNumber: null
  })
})
const cityLabel = computed(() => {
  return organization.value.address &&
    organization.value.address !== organization.value.city
    ? `${organization.value.zipCode} ${organization.value.city}`
    : `${organization.value.city} (${organization.value.zipCode})`
})
const hasLocation = computed(() => {
  return (
    organization.value.address ||
    organization.value.zipCode ||
    organization.value.city
  )
})

const showPhoneNumber = () => {
  isPhoneNumberShown.value = true
}
const showEmail = () => {
  isEmailShown.value = true
}
const openMail = () => {
  window.location = `mailto:${contact.value.email}` as any
}
</script>

<style lang="scss" scoped>
@import './styles/infos';
</style>

<style lang="scss">
.Infos {
  + .Socials {
    margin-top: $ha-spacing-large;

    @include mediaQuery(600) {
      margin-top: $ha-spacing-big;
    }
  }
}
</style>
